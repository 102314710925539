import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
    accessToken: string;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    accessToken: string;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
    accessToken: string;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  accessToken: "",
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      accessToken: action.payload.accessToken,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      accessToken: action.payload.accessToken,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      accessToken: action.payload.accessToken,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      accessToken: "",
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const sessionaccessToken = storageAvailable ? sessionStorage.getItem('accessToken') : '';

      if (sessionaccessToken && isValidToken(sessionaccessToken)) {
        setSession(sessionaccessToken);

        const response = await axios.get(`/company-user/account`);

        const { user, accessToken} = response.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
          accessToken,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          accessToken: "",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
          accessToken: "",
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const response = await axios.post('/company-user/login', {
      email,
      password,
    });

    const { accessToken, user } = response.data;

    if (storageAvailable && accessToken) {
      sessionStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.Authorization = `Bearer ${accessToken}`

    }

    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
        accessToken,
      },
    });
  }, [storageAvailable]);

  // REGISTER
  const register = useCallback(
    async ( email: string, password: string, firstName: string, lastName: string, mobileNumber: string, companyId: string, comingFrom: string) => {
      const response = await axios.post('/company-user/register/open', {
        comingFrom,
        email,
        password,
        firstName,
        lastName,
        mobileNumber,
        companyId,
        role: "admin"
      });
      const { accessToken, user } = response.data;

      if (storageAvailable && accessToken) {
        sessionStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.Authorization = `Bearer ${accessToken}`
  
      }

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
          accessToken,
        },
      });
    },
    [storageAvailable]
  );

  // LOGOUT
  const logout = useCallback(() => {
    setSession("");
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      accessToken: state.accessToken,
      method: 'jwt',
      login,
      loginWithGoogle: () => { },
      loginWithGithub: () => { },
      loginWithTwitter: () => { },
      register,
      logout,
      initialize,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, state.accessToken, login, logout, register, initialize]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
