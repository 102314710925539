import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  ContentBlock,
  DraftHandleValue
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import Toolbar from "./Toolbar/Toolbar";
import "./DraftEditor.css";

type Props = {
  setHtmlDescription: (arg0: string) => void;
  maxTextLength: number;
  htmlDescription: string;
}

const DraftEditor = (props: Props) => {
  const { setHtmlDescription, maxTextLength, htmlDescription} = props;
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      convertFromRaw({
        blocks: [],
        entityMap: {}
      })
    )
  );

  const clearEditorContent = useCallback(() => {
    setEditorState(
      EditorState.createWithContent(
        convertFromRaw({
          blocks: [],
          entityMap: {},
        })
      )
    );
  },[]);

    useEffect(() => {
      if (htmlDescription === "") {
        clearEditorContent()
      }
    }, [htmlDescription, clearEditorContent]);


  const editor = useRef<Editor | null>(null);

  useEffect(() => {
    focusEditor();
  }, []);

  const focusEditor = () => {
    if (editor.current) {
      editor.current.focus();
    }
  };

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();

      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== currentContent.getKeyAfter(endKey)) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handleBeforeInput = (chars: any, editorStateVal: { getCurrentContent: () => any; }, eventTimeStamp: any) => {
    const currentContent = editorStateVal.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > maxTextLength - 1) {
      setErrorMsg('Max character limit reached!');
      setTimeout(() => {
        setErrorMsg(null);
      }, 4000);

      return 'handled';
    }
    setErrorMsg(null);
    return 'not-handled';
  };

  const handlePastedText = (text: string | any[], html: any, editorStateVal: { getCurrentContent: () => any; }) => {
    const currentContent = editorStateVal.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (currentContentLength + text.length - selectedTextLength > maxTextLength) {
      setErrorMsg('Max character limit reached!');
      setTimeout(() => {
        setErrorMsg(null);
      }, 4000);
      return 'handled';
    }
    setErrorMsg(null);
    return 'not-handled';
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const styleMap: { [key: string]: React.CSSProperties } = {
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
    HIGHLIGHT: {
      backgroundColor: "#F7A5F7",
    },
    UPPERCASE: {
      textTransform: "uppercase",
    },
    LOWERCASE: {
      textTransform: "lowercase",
    },
    CODEBLOCK: {
      fontFamily: '"fira-code", "monospace"',
      fontSize: "inherit",
      background: "#ffeff0",
      fontStyle: "italic",
      lineHeight: 1.5,
      padding: "0.3rem 0.5rem",
      borderRadius: " 0.2rem",
    },
    SUPERSCRIPT: {
      verticalAlign: "super",
      fontSize: "80%",
    },
    SUBSCRIPT: {
      verticalAlign: "sub",
      fontSize: "80%",
    },
  };

  const myBlockStyleFn = (contentBlock: ContentBlock): string => {
    const type = contentBlock.getType();
    switch (type) {
      case "blockQuote":
        return "superFancyBlockquote";
      case "leftAlign":
        return "leftAlign";
      case "rightAlign":
        return "rightAlign";
      case "centerAlign":
        return "centerAlign";
      case "justifyAlign":
        return "justifyAlign";
      default:
        return "";
    }
  };

  const convertContentToHTML = useMemo(() => {
    const contentState = editorState.getCurrentContent();
    const contentHTML = draftToHtml(convertToRaw(contentState));
    return contentHTML;
  }, [editorState]);


  useEffect(() => {
    setHtmlDescription(convertContentToHTML)
  }, [editorState, convertContentToHTML, setHtmlDescription])
  
  return (
    <div className="editor-wrapper" onClick={focusEditor}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          focusEditor();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <Toolbar editorState={editorState} setEditorState={setEditorState} />
      <div className="editor-container">
        <Editor
          ref={editor}
          placeholder="Write Here"
          handleKeyCommand={handleKeyCommand}
          editorState={editorState}
          customStyleMap={styleMap}
          blockStyleFn={myBlockStyleFn}

          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}

          onChange={(editorStateVal) => {
            setEditorState(editorStateVal);
          }}
        />
        {(maxTextLength - editorState.getCurrentContent().getPlainText('').length) > 0 ? (
          <p style={{ fontSize: "0.9rem", color: "#727273", marginTop: "0.5rem" }}>
            {maxTextLength - editorState.getCurrentContent().getPlainText('').length} characters remaining
          </p>
        ) : (
          <p style={{ fontSize: "0.9rem", color: "red", marginTop: "0.5rem" }}>
            {errorMsg}
          </p>
        )}
      </div>
    </div>
  );
};

export default DraftEditor;
